import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>S+J+$</title>
            <meta name="description" content="Registry" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Registry</h1>
                        Celebrating with you is all we need, but we did make a registry for those so inclined. 😉
                        <div><p></p></div>
                        <div><a href="https://www.zola.com/registry/marzdozorwedding">Check it out on Zola</a></div>
                    </header>
                    </div>
            </section>
        </div>

    </Layout>
)

export default Generic